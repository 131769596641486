<template>
    <v-sheet
        style="padding:100px 100px 120px 100px;"
    >

        <v-sheet
            outlined
            class="pt-10 pb-16 px-16 mx-auto rounded-15"
            width="800"
        >
            <!-- 상단 -->
            <p
                class="text-h6 text-center mb-0"
            >
                오늘의 책 알림
            </p>

            <p
                class="text-body-2 text-center primary--text"
            >
                테스트
            </p>

            <!-- 내용 -->
            <v-divider class="mb-2 mx-2"></v-divider>

            <!-- 답변 전 -->
            <div
                v-if="!answered && !loading"
            >
                <!-- 입력 -->
                <v-text-field
                    style="width:200px;"
                    class="rounded-10 mx-auto mt-6"
                    v-model="name"
                    outlined
                    dense
                    hide-details
                    placeholder="책 이름"
                ></v-text-field>

                <v-btn
                    class="rounded-10 d-flex mx-auto mt-6 mb-4"
                    color="primary"
                    width="160"
                    dark
                    depressed
                    @click="callOpenAI()"
                >
                    책 리뷰하기
                </v-btn>
            </div>

            <!-- 답변 후 -->
            <div
                v-if="answered && !loading"
                class="text-body-1 mt-8 mx-auto"
            >
                <v-img
                    style="width:240px; margin:0 auto; box-shadow:0px 0px 4px 4px #eee; border-radius:5px;"
                    :src="book_image"
                ></v-img>
            </div>

            <!-- 답변 후 -->
            <div
                v-if="answered && !loading"
                class="text-body-1 mt-4 mx-auto pr-2 mt-8"
                style="width:400px; white-space: pre-line;"
            >
                <!-- {{response}} -->
                <div v-html="formattedResponse"></div>
            </div>

            <!-- 로딩 -->
            <v-progress-circular
                v-if="loading"
                indeterminate
                color="primary"
                class="d-flex mt-14 mb-12 mx-auto"
                size="60"
            ></v-progress-circular>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        name: "",
        apiKey: 'sk-proj-XAXWwzMSlSzeKCYZvNPJT3BlbkFJxhdW1rGp6Do6Sej0JvQS',
        response: '',
        answered: false,
        loading: false,

        book_image: ""
    }),

    computed: {
        formattedResponse() {
            return this.response.replace(/^(\d+\.\s+[^:]+:)/gm, '<div class="font-weight-bold text-subtitle-1" style="margin-bottom:-12px;">$1</div>')
        }
    },

    mounted(){
        // this.$http.post("/api/search/book", {
        //     params: {
        //         title: this.name
        //     }
        // }).then((res) => {
        //     console.log(res)
        // })
    },

    methods: {
        async callOpenAI() {
            if(this.name == ""){
                alert("입력란이 비어있습니다")
                return
            }

            this.loading = true


            const bookInfoResult = await this.$http.post("/api/search/book", {
                params: {
                    title: this.name
                }
            })


            const bookInfo = bookInfoResult.data.documents[0]


            console.log(bookInfo)

            this.book_image = bookInfo.thumbnail

            // ChatGPT 프롬프트 생성
            this.prompt = `'${this.name}'이란 책에 대한 정보입니다:
            - 제목: ${bookInfo.title}
            - 저자: ${bookInfo.authors.join(', ')}
            - 출판사: ${bookInfo.publisher}
            - 출판일: ${bookInfo.datetime}
            - 설명: ${bookInfo.contents}

            위 정보를 참고하여 이 책의 내용을 분석하고 최대한 교훈적으로 요약해주세요. 그리고 내용은 다음과 같이 4개의 문단으로 설명해주세요:
            1. 핵심 메세지
            2. 저자 소개
            3. 주요 주제
            4. 주요 인사이트
            
            주요 주제는 목록 형태로 6 ~ 10개의 주제 형태로 적어줘
            주요 인사이트는 목록 형태로 6 ~ 10개의 인사이트 형태로 적어줘
            `

            try {
                const result = await this.$http.post('https://api.openai.com/v1/chat/completions', {
                    model: 'gpt-3.5-turbo',
                    messages: [{ role: 'user', content: this.prompt }],
                    temperature: 0,
                    n: 1
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.apiKey}`
                    }
                })
                console.log(result)
                this.loading = false
                this.response = result.data.choices[0].message.content
                // .replace(/\n/g, '\n\n')
                console.log(this.response)
                this.answered = true
            } catch (error) {
                console.error('Error calling OpenAI API:', error)
            }
        },
    }
}
</script> 