<template>
    <div>
        <!-- # PC -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
        >
            <!-- # 섹션 1 -->
            <v-sheet
                class="pa-5 pt-14 pb-12"
                color="#f8faff"
            >
                <p
                    class="banner_font text-center mb-0"
                    style="font-size:28px;"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                >
                    매일 새로운 책을 발견하다
                </p>
                <v-img
                    class="mx-auto"
                    width="240"
                    contain
                    data-aos="fade-up"
                    data-aos-duration="2000"
                    src="@/assets/home/section1.png"
                ></v-img>

                <v-select
                    style="width:280px; background:white;"
                    class="shrink mx-auto rounded-10 mt-4"
                    label="카테고리"
                    outlined
                    hide-details
                    item-text="name"
                    item-value="value"
                    :items="select_list.category"
                    v-model="category"
                ></v-select>

                <v-text-field
                    style="width:280px; background:white;"
                    class="shrink mx-auto rounded-10 mt-4"
                    label="시간"
                    outlined
                    hide-details
                    v-model="time"
                    type="time"
                ></v-text-field>

                <v-select
                    style="width:280px; background:white;"
                    class="shrink mx-auto rounded-10 mt-4"
                    label="알림방식"
                    outlined
                    hide-details
                    :items="select_list.send_type"
                    v-model="send_type"
                ></v-select>

                <v-btn
                    width="200"
                    class="d-flex mx-auto mt-8 rounded-10"
                    dark
                    large
                    depressed
                    color="#079aaa"
                    @click="callOpenAI()"
                >
                    오늘의 책 알림 설정하기
                </v-btn>
            </v-sheet>
        </div>

        <!-- # Mobile -->
        <div
            v-if="$vuetify.breakpoint.mobile"
        >
            <!-- # 섹션 1 -->
            <v-sheet
                class="pt-14 pb-16"
                color="#f8faff"
            >
                <p
                    class="banner_font text-center mb-0"
                    style="font-size:22px;"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                >
                    매일 새로운 책을 발견하다
                </p>
                <v-img
                    class="mx-auto"
                    width="50%"
                    contain
                    data-aos="fade-up"
                    data-aos-duration="2000"
                    src="@/assets/home/section1.png"
                ></v-img>

                <v-select
                    style="width:240px; background:white;"
                    class="shrink mx-auto rounded-10 mt-4"
                    label="카테고리"
                    outlined
                    hide-details
                    item-text="name"
                    item-value="value"
                    :items="select_list.category"
                    v-model="category"
                ></v-select>

                <v-text-field
                    style="width:240px; background:white;"
                    class="shrink mx-auto rounded-10 mt-4"
                    label="시간"
                    outlined
                    hide-details
                    v-model="time"
                    type="time"
                ></v-text-field>

                <v-select
                    style="width:240px; background:white;"
                    class="shrink mx-auto rounded-10 mt-4"
                    label="알림방식"
                    outlined
                    hide-details
                    :items="select_list.send_type"
                    v-model="send_type"
                ></v-select>

                <v-btn
                    width="200"
                    class="d-flex mx-auto mt-8 rounded-10"
                    dark
                    large
                    depressed
                    color="#079aaa"
                    @click="callOpenAI()"
                >
                    오늘의 책 알림 설정하기
                </v-btn>
            </v-sheet>
        </div>
    </div>
</template>
<script>
export default {
    data: () => ({
        select_list: {
            category: [
                { value: "랜덤", name: "랜덤하게 책을 추천해줘" },
                { value: "자기계발", name: "자기계발 관련 책을 추천해줘" },
                { value: "여행", name: "여행 관련 책을 추천해줘" },
                { value: "건강", name: "건강 관련 책을 추천해줘" },
                { value: "요리", name: "요리 관련 책을 추천해줘" },
                { value: "힐링", name: "힐링 관련 책을 추천해줘" },
                { value: "역사", name: "역사 관련 책을 추천해줘" },
                { value: "예술", name: "예술 관련 책을 추천해줘" },
                { value: "종교", name: "종교 관련 책을 추천해줘" },
                { value: "철학", name: "철학 관련 책을 추천해줘" },
                { value: "심리학", name: "심리학 관련 책을 추천해줘" },
                { value: "의학", name: "의학 관련 책을 추천해줘" },
                { value: "경제", name: "경제 관련 책을 추천해줘" },
                { value: "육아", name: "육아 관련 책을 추천해줘" },
            ],

            send_type: [
                "카톡",
                "이메일",
            ]
        },

        category: {},
        time: null,
        send_type: "",

        name: "여행",
        response: '',
        answered: false,
        loading: false,
        book_image: ""
    }),

    computed: {
        formattedResponse() {
            return this.response.replace(/^(\d+\.\s+[^:]+:)/gm, '<div class="font-weight-bold text-subtitle-1" style="margin-bottom:-12px;">$1</div>')
        }
    },

    methods: {
        async callOpenAI() {
            if(!this.$store.state.page_user.is_logined){
                alert("로그인 후 이용가능합니다")
                this.$router.push("/auth/login")
                return
            }

            if(this.category == ""){
                alert("입력란이 비어있습니다")
                return
            }

            if(this.send_type == ""){
                alert("알림방식이 비어있습니다")
                return
            }

            let random_category = this.category

            // 랜덤 옵션이 선택된 경우
            if(random_category == "랜덤") {
                const nonRandomCategories = this.select_list.category.filter(cat => cat.value != "랜덤")
                const randomIndex = Math.floor(Math.random() * nonRandomCategories.length)
                random_category = nonRandomCategories[randomIndex].value
            }

            this.$http.post("/api/service/book", {
                params: {
                    title: this.category == '랜덤'? random_category : this.category,
                    time: this.time,
                    user_id: this.$store.state.page_user.user_id,
                }
            })
            alert("책 알림이 설정되었습니다.")
        },
    }
}
</script>
<style>
@font-face {
    font-family: 'Hakgyoansim';
    src: url('~@/assets/fonts/Hakgyoansim Badasseugi OTF L.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Hakgyoansim';
    src: url('~@/assets/fonts/Hakgyoansim Badasseugi OTF B.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

.logo_font {
    font-family: 'LaBelleAutrore', sans-serif !important;
    font-weight: 500;
    font-size:24px;
}

.banner_font {
    font-family: 'Hakgyoansim', sans-serif !important;
    font-weight: 400;
    font-size:60px;
}

.request_font {
    font-family: 'Hakgyoansim', sans-serif !important;
    font-weight: 400;
    font-size:22px;
}
</style>