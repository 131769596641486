<template>
    <div>
        <!-- # PC -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
        >
            <!-- # 섹션 3 -->
            <v-sheet
                class="pa-5 pt-16 pb-16"
                color="#ddede3"
            >
                <p
                    class="banner_font text-center mb-0"
                    style="font-size:28px;"
                    data-aos="fade-up"
                    data-aos-duration="3200"
                >
                    매일 새로운 글귀를 보내드립니다
                </p>

                <v-img
                    class="mx-auto"
                    width="240"
                    contain
                    data-aos="fade-up"
                    data-aos-duration="3200"
                    src="@/assets/home/section3.png"
                ></v-img>

                <v-select
                    style="width:240px; background:white;"
                    class="shrink mx-auto rounded-10 mt-4"
                    label="카테고리"
                    outlined
                    hide-details
                    :items="select_list.category"
                    v-model="category"
                ></v-select>

                <v-text-field
                    style="width:240px; background:white;"
                    class="shrink mx-auto rounded-10 mt-4"
                    label="시간"
                    outlined
                    hide-details
                    v-model="time"
                    type="time"
                ></v-text-field>

                <v-select
                    style="width:240px; background:white;"
                    class="shrink mx-auto rounded-10 mt-4"
                    label="알림방식"
                    outlined
                    hide-details
                    :items="select_list.send_type"
                    v-model="send_type"
                ></v-select>

                <v-btn
                    width="200"
                    class="d-flex mx-auto mt-8 rounded-10"
                    dark
                    large
                    depressed
                    color="#079aaa"
                    @click="callOpenAI(category)"
                >
                    오늘의 글 알림 설정하기
                </v-btn>
            </v-sheet>
        </div>

        <!-- # Mobile -->
        <div
            v-if="$vuetify.breakpoint.mobile"
        >
            <!-- # 섹션 3 -->
            <v-sheet
                class="pa-5 pt-16 pb-16"
                color="#ddede3"
            >
                <p
                    class="banner_font text-center mb-0"
                    style="font-size:20px;"
                    data-aos="fade-up"
                    data-aos-duration="3200"
                >
                    매일 새로운 글귀를 보내드립니다
                </p>

                <v-img
                    class="mx-auto"
                    width="50%"
                    contain
                    data-aos="fade-up"
                    data-aos-duration="3200"
                    src="@/assets/home/section3.png"
                ></v-img>

                <v-select
                    style="width:240px; background:white;"
                    class="shrink mx-auto rounded-10 mt-4"
                    label="카테고리"
                    outlined
                    hide-details
                    :items="select_list.category"
                    v-model="category"
                ></v-select>

                <v-text-field
                    style="width:240px; background:white;"
                    class="shrink mx-auto rounded-10 mt-4"
                    label="시간"
                    outlined
                    hide-details
                    v-model="time"
                    type="time"
                ></v-text-field>

                <v-select
                    style="width:240px; background:white;"
                    class="shrink mx-auto rounded-10 mt-4"
                    label="알림방식"
                    outlined
                    hide-details
                    :items="select_list.send_type"
                    v-model="send_type"
                ></v-select>

                <v-btn
                    width="200"
                    class="d-flex mx-auto mt-8 rounded-10"
                    dark
                    large
                    depressed
                    color="#079aaa"
                    @click="callOpenAI(category)"
                >
                    오늘의 글 알림 설정하기
                </v-btn>
            </v-sheet>
        </div>
    </div>
</template>
<script>
export default {
    data: () => ({
        select_list: {
            category: [
                "랜덤",
                "힐링",
                "동기부여",
                "성공",
                "경제",
                "나이",
                "혼자",
                "놀라운",
                "분노",
                "건축",
                "예술",
                "태도",
                "아름다움",
                "최고",
                "생일",
                "사업",
                "자동차",
                "변화",
                "소통",
                "컴퓨터",
                "멋진",
                "용기",
                "아빠",
                "데이트",
                "죽음",
                "디자인",
                "꿈",
                "교육",
                "환경",
                "평등",
                "경험",
                "실패",
                "믿음",
                "가족",
                "유명한",
                "두려움",
                "피트니스",
                "음식",
                "용서",
                "자유",
                "우정",
                "웃긴",
                "미래",
                "신",
                "좋은",
                "정부",
                "졸업",
                "위대한",
                "행복",
                "건강",
                "역사",
                "집",
                "유머",
                "상상력",
                "영감",
                "지능",
                "질투",
                "지식",
                "리더십",
                "학습",
                "법률",
                "삶",
                "사랑",
                "결혼",
                "의료",
                "남자",
                "엄마",
                "아침",
                "영화"
            ],

            send_type: [
                "카톡",
                "이메일",
            ]
        },

        category_mapping: [
            { type: "힐링", category: "hope" },
            { type: "동기부여", category: "motivation" },
            { type: "성공", category: "success" },
            { type: "경제", category: "money" },
            { type: "나이", category: "age" },
            { type: "혼자", category: "alone" },
            { type: "놀라운", category: "amazing" },
            { type: "분노", category: "anger" },
            { type: "건축", category: "architecture" },
            { type: "예술", category: "art" },
            { type: "태도", category: "attitude" },
            { type: "아름다움", category: "beauty" },
            { type: "최고", category: "best" },
            { type: "생일", category: "birthday" },
            { type: "사업", category: "business" },
            { type: "자동차", category: "car" },
            { type: "변화", category: "change" },
            { type: "소통", category: "communication" },
            { type: "컴퓨터", category: "computers" },
            { type: "멋진", category: "cool" },
            { type: "용기", category: "courage" },
            { type: "아빠", category: "dad" },
            { type: "데이트", category: "dating" },
            { type: "죽음", category: "death" },
            { type: "디자인", category: "design" },
            { type: "꿈", category: "dreams" },
            { type: "교육", category: "education" },
            { type: "환경", category: "environmental" },
            { type: "평등", category: "equality" },
            { type: "경험", category: "experience" },
            { type: "실패", category: "failure" },
            { type: "믿음", category: "faith" },
            { type: "가족", category: "family" },
            { type: "유명한", category: "famous" },
            { type: "두려움", category: "fear" },
            { type: "피트니스", category: "fitness" },
            { type: "음식", category: "food" },
            { type: "용서", category: "forgiveness" },
            { type: "자유", category: "freedom" },
            { type: "우정", category: "friendship" },
            { type: "웃긴", category: "funny" },
            { type: "미래", category: "future" },
            { type: "신", category: "god" },
            { type: "좋은", category: "good" },
            { type: "정부", category: "government" },
            { type: "졸업", category: "graduation" },
            { type: "위대한", category: "great" },
            { type: "행복", category: "happiness" },
            { type: "건강", category: "health" },
            { type: "역사", category: "history" },
            { type: "집", category: "home" },
            { type: "유머", category: "humor" },
            { type: "상상력", category: "imagination" },
            { type: "영감", category: "inspirational" },
            { type: "지능", category: "intelligence" },
            { type: "질투", category: "jealousy" },
            { type: "지식", category: "knowledge" },
            { type: "리더십", category: "leadership" },
            { type: "학습", category: "learning" },
            { type: "법률", category: "legal" },
            { type: "삶", category: "life" },
            { type: "사랑", category: "love" },
            { type: "결혼", category: "marriage" },
            { type: "의료", category: "medical" },
            { type: "남자", category: "men" },
            { type: "엄마", category: "mom" },
            { type: "아침", category: "morning" },
            { type: "영화", category: "movies" }
        ],

        category: {},
        time: null,

        send_type: "",

        name: "",
        apiKey: 'sk-proj-XAXWwzMSlSzeKCYZvNPJT3BlbkFJxhdW1rGp6Do6Sej0JvQS',
        prompt: '',
        prompt2: '',
        response1: '',
        response2: '',
        answered: false,
        loading: false,

        quote_origin: "",
        author_origin: "",

        quote: "",
        author: "",

        discardedQuotes: []
    }),

    methods: {
        async addQuote(quote){
            await this.$http.post("/api/quote/insert", {
                params: {
                    user_id: this.$store.state.page_user.user_id,
                    quote: quote,
                }
            }).then((res => {
                console.log("add", res)
            }))
        },

        async loadQuoteList(){
            await this.$http.post("/api/quote/select", {
                params: {
                    user_id: this.$store.state.page_user.user_id
                }
            }).then((res => {
                console.log("load", res)
                if(res.data.length){
                    this.discardedQuotes = res.data
                }else{
                    this.discardedQuotes = []
                }
            }))
        },

        // TYPE -> CATEGORY
		getCategoryByType(type) {
            if (type === '랜덤') {
                const randomIndex = Math.floor(Math.random() * this.category_mapping.length)
                return this.category_mapping[randomIndex].category
            }
			const found = this.category_mapping.find(item => item.type === type)
			return found ? found.category : "기타"
		},

        async loop(type) {
            let category = this.getCategoryByType(type)
            this.type = type
            this.loading = true

            await this.loadQuoteList()

            let quote = ''
            let author = ''
            let response = {}

            try {
                while (true) {
                    quote = ''
                    author = ''

                    await new Promise(resolve => setTimeout(resolve, 2000))

                    response = await this.$http.get('https://api.api-ninjas.com/v1/quotes?category=' + category, {
                        headers: {
                            'X-Api-Key': 'fg+3Qonx/CVippl44ab9Pg==woOe5WuAQezWd3XA'
                        }
                    })

                    console.log(response)

                    if(response.data.length){
                        quote = response.data[0].quote
                        author = response.data[0].author

                        if (!this.discardedQuotes.includes(quote)) {
                            break
                        }
                    }
                }
            } catch (error) {
                console.error('API 호출 중 오류 발생:', error)
                // 필요한 경우 추가 오류 처리 로직을 여기에 작성
            }

            // 폐기할 명언이 아닌 값을 얻었으므로 이후 로직 수행
            this.quote_origin = quote
            this.author_origin = author
                    
            console.log(this.quote_origin)
            console.log(this.author_origin)

            if(this.quote_origin){
                this.addQuote(this.quote_origin)
            }
        },

        async callOpenAI(type) {
            if(this.send_type == ""){
                alert("알림방식이 비어있습니다")
                return
            }

            if(!this.$store.state.page_user.is_logined){
                alert("로그인 이후 이용가능합니다")
                this.$router.push("/auth/login")
                return
            }else{
                alert("글 알림이 설정되었습니다.")
            }

            this.quote_origin = ""
            this.author_origin = ""
            this.prompt = ""

            // # 명언 체크
            await this.loop(type)

            // # 명언 번역
            try {
                const result = await this.$http.post('https://api.openai.com/v1/chat/completions', {
                    model: 'gpt-3.5-turbo',
                    messages: [{ role: 'user', content: "'" + this.quote_origin + "'를 한글로 번역해줘" }],
                    temperature: 0.7, // 0 ~ 1 : 0은 거의 고정, 1에 가까울수록 불규칙적이고 다양한 응답
                    n: 1
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.apiKey}`
                    }
                })
                console.log(result)
                this.quote = result.data.choices[0].message.content
                // .replace(/\n/g, '\n\n')
                this.answered = true
            } catch (error) {
                console.error('Error calling OpenAI API:', error)
            }

            // # 작자 번역
            try {
                const result = await this.$http.post('https://api.openai.com/v1/chat/completions', {
                    model: 'gpt-3.5-turbo',
                    messages: [{ role: 'user', content: "'" + this.author_origin + "'는 이름인데 한글로 번역해줘" }],
                    temperature: 0.7, // 0 ~ 1 : 0은 거의 고정, 1에 가까울수록 불규칙적이고 다양한 응답
                    n: 1
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.apiKey}`
                    }
                })
                console.log(result)
                this.author = result.data.choices[0].message.content
                // .replace(/\n/g, '\n\n')
                this.answered = true
            } catch (error) {
                console.error('Error calling OpenAI API:', error)
            }

            // # 명언 설명
            this.prompt += "author : " + this.author_origin
            this.prompt += "quote : " + this.quote_origin
            this.prompt += "\n"
            this.prompt += "위에 갖고온 데이터는 명언인데 한국어로 해당 명언에 대해 200자 이내로 설명해줄래?"

            try {
                const result = await this.$http.post('https://api.openai.com/v1/chat/completions', {
                    model: 'gpt-3.5-turbo',
                    messages: [{ role: 'user', content: this.prompt }],
                    temperature: 0.7, // 0 ~ 1 : 0은 거의 고정, 1에 가까울수록 불규칙적이고 다양한 응답
                    n: 1
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.apiKey}`
                    }
                })
                console.log(result)
                this.loading = false
                this.explain = result.data.choices[0].message.content
                // .replace(/\n/g, '\n\n')
                console.log(this.explain)
                this.answered = true
            } catch (error) {
                console.error('Error calling OpenAI API:', error)
            }

            // 이메일 전송 요청
            try {
                await this.$http.post('/api/service/text', {
                    params: {
                        user_id: this.$store.state.page_user.user_id,
                        quote: this.quote,
                        quote_origin: this.quote_origin,
                        author: this.author,
                        author_origin: this.author_origin,
                        explain: this.explain,
                        time: this.time
                    }
                })
                console.log('Email sent successfully')
            } catch (error) {
                console.error('Failed to send email:', error)
            }
        },
    }
}
</script>
<style>
@font-face {
    font-family: 'Hakgyoansim';
    src: url('~@/assets/fonts/Hakgyoansim Badasseugi OTF L.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Hakgyoansim';
    src: url('~@/assets/fonts/Hakgyoansim Badasseugi OTF B.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

.logo_font {
    font-family: 'LaBelleAutrore', sans-serif !important;
    font-weight: 500;
    font-size:24px;
}

.banner_font {
    font-family: 'Hakgyoansim', sans-serif !important;
    font-weight: 400;
    font-size:60px;
}

.request_font {
    font-family: 'Hakgyoansim', sans-serif !important;
    font-weight: 400;
    font-size:22px;
}
</style>